import { createSlice } from '@reduxjs/toolkit';
export const typeSlice = createSlice({
	name: 'modalType',
	initialState: {
		modalType: 'default',
	},
	reducers: {
		setModalType: (state, action) => {
			state.modalType = action.payload;
		},
	},
});
export const { setModalType } = typeSlice.actions;
export default typeSlice.reducer;
