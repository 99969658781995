import React from 'react';
import styled from 'styled-components';
import { CopyIcon } from '../../Icons/Icons';
import { ROUTES } from '../constants';
import { FormattedMessage } from 'react-intl';

import {
	FormWrapper,
	FormContentWrapper,
	Footer,
	FormTitle,
	FormContent,
	HelpWrapper,
	HelpText,
	ContentWrapper,
	FormTitleValue,
} from '../ReferralModal';
import { Input } from '../../Components/Input';
import { HelpIcon, CheckedCircle } from '../../Icons/Icons';

const ImageWrapper = styled.div`
	position: relative;
	width: 440px;
	@media (max-width: 930px) {
		width: 360px;
		padding-bottom: 20px;
	}
`;

const CenteredCopiedWrapper = styled.div`
	justify-content: center;
	display: flex;
	position: relative;
`;

const CopiedText = styled.span`
	color: #149954;
	font-family: Noto Sans;
	font-size: 16px;
	font-weight: 600;
`;

const CopiedTextContent = styled.div`
	background: #ecf7f1;
	padding: 4px 10px 4px 10px;
	gap: 8px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	position: absolute;
`;

const InputWrapper = styled.div`
	cursor: pointer;
	margin-bottom: 20px;
`;

const QrImageWrapper = styled.div`
	${({ qrCode }) =>
		!qrCode
			? 'display: none;'
			: `


`}
	@media (max-width: 930px) {
		background: #fff;
		width: 222px;
		height: 222px;
		border-radius: 28px;
		border: 1px solid;
		border-image-source: linear-gradient(
			180deg,
			rgba(224, 224, 224, 0) 0%,
			#e0e0e0 100%
		);
		position: absolute;
		top: 55px;
		left: 75px;
	}
`;

const QrCodeImg = styled.div`
	${({ qrCode }) => (!qrCode ? 'display: none;' : '')}
	@media (max-width: 930px) {
		background-image: ${({ qrCode }) => qrCode && `url(${qrCode})`};
		background-size: 158px 158px;
		background-repeat: no-repeat;
		position: absolute;
		top: 32px;
		left: 32px;
		width: 100%;
		height: 100%;
	}
`;

export const ShareLinkView = ({
	shareLink,
	showCopiedLinkInfo,
	handleLinkCopy,
	handleNextStep,
}) => {
	const breakpoint = 930;
	const width = window.innerWidth;

	return (
		<ContentWrapper height="fit-content">
			<ImageWrapper>
				<picture>
					<source
						media="(max-width: 930px)"
						srcSet="https://ref.skycop.com/img/mobileImageMasked.jpg"
					/>
					<source
						media="(min-width: 930px)"
						srcSet="https://ref.skycop.com/img/image.jpg"
					/>
					<img src="https://ref.skycop.com/img/image.jpg" />
				</picture>
				<QrImageWrapper qrCode={shareLink.qrLink}>
					<QrCodeImg id="qrlogo" qrCode={shareLink.qrLink} />
				</QrImageWrapper>
			</ImageWrapper>
			<FormWrapper>
				<FormContentWrapper>
					<FormTitle>
						<FormattedMessage
							id="referral.default.view.title"
							values={{
								amount: (
									<FormTitleValue>
										<FormattedMessage id="referral.default.view.title.amount" />
									</FormTitleValue>
								),
							}}
						/>
					</FormTitle>
					<FormContent>
						{width < breakpoint ? (
							<FormattedMessage id="referral.share.link.description.mobile" />
						) : (
							<FormattedMessage id="referral.share.link.description.desktop" />
						)}
					</FormContent>

					<InputWrapper onClick={() => handleLinkCopy(shareLink.link)}>
						<Input
							defaultValue={shareLink.link}
							customIcon={<CopyIcon />}
							readOnly={true}
							isCopyEnabled={true}
						/>
					</InputWrapper>

					{showCopiedLinkInfo && (
						<CenteredCopiedWrapper>
							<CopiedTextContent>
								<CheckedCircle />
								<CopiedText>
									<FormattedMessage id="referral.share.link.copied" />
								</CopiedText>
							</CopiedTextContent>
						</CenteredCopiedWrapper>
					)}
				</FormContentWrapper>
				<Footer>
					<HelpWrapper onClick={() => handleNextStep(ROUTES.HOW_IT_WORKS)}>
						<HelpIcon />
						<HelpText>
							<FormattedMessage id="referral.how.it.works.link.title" />
						</HelpText>
					</HelpWrapper>
				</Footer>
			</FormWrapper>
		</ContentWrapper>
	);
};
