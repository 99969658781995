import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowLeft } from '../../Icons/Icons';
import { ContentWrapper, ImageWrapper } from '../LennuabiModal';
import { TermsAndConditionsFooter } from './TermsAndConditions';

const Footer = styled.div`
	min-height: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 12px;
	font-weight: 450;
	line-height: 17.76px;
	color: #002864;

	svg {
		margin-left: 9px;
	}
`;

export const HelpWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const HelpText = styled.div`
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 12px;
	font-weight: 450;
	line-height: 17.76px;
	text-align: left;
	color: #002864;
	margin-right: 3px;
	display: inline;

	@media (max-width: 930px) {
		font-size: 14px;
	}
`;

const BackBtnContainer = styled.div`
	position: absolute;
	top: 30px;
	display: flex;
	cursor: pointer;
	width: fit-content;
	align-items: center;

	@media (max-width: 930px) {
		position: static;
		margin-top: 32px;
	}
`;

const BackBtnText = styled.span`
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 14px;
	font-weight: 450;
	line-height: 20.72px;
	text-align: left;
	color: #002864;
	margin-left: 9px;
`;

const Content = styled.div`
	padding: 0px 53px 0px 40px;
	width: 492px;
	display: flex;
	flex-direction: column;
	overflow: scroll;

	@media (max-width: 930px) {
		margin: 0px 19.5px 0px 19.5px;
		width: unset;
		padding: unset;
		overflow-x: hidden;
	}
`;

const FormTitle = styled.h2`
	margin-bottom: 32px;
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 44px;
	font-weight: 500;
	line-height: 52.8px;
	margin-top: 146px;
	color: #002864;

	@media (max-width: 930px) {
		margin-top: 61px;
		font-size: 36px;
		line-height: 43.2px;
	}
`;

const ContentWrapperInternal = styled.div`
	flex-grow: 1;

	@media (max-width: 930px) {
		margin-bottom: 44px;
	}
`;

const StepsWrapper = styled.div`
	border-top: 1px solid #eef4fd;
	border-bottom: 1px solid #eef4fd;
	padding: 16px 0px;
`;

const Step = styled.div`
	color: #fabe00;
	display: -webkit-box;
	&:not(:last-child) {
		margin-bottom: 16px;
	}
`;

const StepLabel = styled.div`
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 14px;
	font-weight: 450;
	line-height: 20.72px;
	text-align: left;

	display: flex;
	width: fit-content;
	width: 24px;
	height: 24px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 1.5px solid #fabe00;
	aspect-ratio: 1 / 1;
`;

const StepDescription = styled.div`
	font-family: 'CircularStd-Book', sans-serif;
	font-size: 16px;
	font-weight: 450;
	line-height: 23.68px;
	text-align: left;
	color: #002864;
	margin-left: 16px;
	margin-right: 24px;

	@media (max-width: 930px) {
		margin-right: 19.5px;
	}
`;

const HelpTextWrapper = styled.div``;

const steps = (intl, isFriend) => [
	{
		label: 1,
		rightContent: isFriend
			? intl.formatMessage({
					id: 'referral.lennuabi.how.it.works.1.step.friend',
			  })
			: intl.formatMessage({
					id: 'referral.lennuabi.how.it.works.1.step.advocate',
			  }),
	},
	{
		label: 2,
		rightContent: isFriend
			? intl.formatMessage({
					id: 'referral.lennuabi.how.it.works.2.step.friend',
			  })
			: intl.formatMessage({
					id: 'referral.lennuabi.how.it.works.2.step.advocate',
			  }),
	},
	{
		label: 3,
		rightContent: isFriend
			? intl.formatMessage({
					id: 'referral.lennuabi.how.it.works.3.step.friend',
			  })
			: intl.formatMessage({
					id: 'referral.lennuabi.how.it.works.3.step.advocate',
			  }),
	},
	{
		label: 4,
		rightContent: isFriend
			? intl.formatMessage({
					id: 'referral.lennuabi.how.it.works.4.step.friend',
			  })
			: intl.formatMessage({
					id: 'referral.lennuabi.how.it.works.4.step.advocate',
			  }),
	},
];

export const HowItWorks = ({ isFriend, handleNextStep, prevStep }) => {
	const intl = useIntl();

	return (
		<ContentWrapper height="640px">
			<ImageWrapper>
				<picture>
					<source
						media="(max-width: 930px)"
						srcSet="https://ref.skycop.com/lennuabiMobile.jpg"
					/>
					<source
						media="(min-width: 930px)"
						srcSet="https://ref.skycop.com/lennuabiDesktop.jpg"
					/>
					<img src="https://ref.skycop.com/lennuabiDesktop.jpg" />
				</picture>
			</ImageWrapper>
			<Content>
				<ContentWrapperInternal>
					<BackBtnContainer onClick={() => handleNextStep(prevStep)}>
						<ArrowLeft />
						<BackBtnText>
							<FormattedMessage id="referral.back.button.title" />
						</BackBtnText>
					</BackBtnContainer>
					<FormTitle>
						<FormattedMessage id="referral.how.it.works.link.title" />
					</FormTitle>
					<StepsWrapper>
						{steps(intl, isFriend).map((step) => (
							<Step key={step.label}>
								<StepLabel>{step.label}</StepLabel>
								<StepDescription>{step.rightContent}</StepDescription>
							</Step>
						))}
					</StepsWrapper>
				</ContentWrapperInternal>
				<Footer>
					<HelpTextWrapper>
						<HelpText>
							<FormattedMessage id="referral.how.it.works.more.details" />
						</HelpText>
						<TermsAndConditionsFooter
							link={intl.formatMessage({
								id: 'referral.lennuabi.term.and.condition.link',
							})}
						/>
					</HelpTextWrapper>
				</Footer>
			</Content>
		</ContentWrapper>
	);
};
