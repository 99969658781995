import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox } from '../../Components/Checkbox';
import { Input } from '../../Components/Input';
import { HelpIcon } from '../../Icons/Icons';
import { Button } from '../../Components/Button';
import { TermsAndConditions } from './TermsAndConditions';
import {
	FormWrapper,
	FormContentWrapper,
	Footer,
	FormTitle,
	FormContent,
	FormEmailInputLabel,
	HelpWrapper,
	HelpText,
	InputWrapper,
	ContentWrapper,
	ImageWrapper,
	FormTitleValue,
} from '../ReferralModal';
import { ROUTES } from '../constants';

export const ButtonWrapper = styled.div`
	width: 100%;

	@media (max-width: 930px) {
		margin-bottom: 40px;
	}
`;

const BoldText = styled.span`
	font-weight: 600;
	font-family: 'Open Sans', Arial, sans-serif;
`;

export const EmailInputView = ({
	email,
	handleEmailChange,
	isChecked,
	handleTermsCheckbox,
	error,
	handleEmailSubmit,
	isCheckboxMarked,
	handleNextStep,
}) => {
	const intl = useIntl();

	return (
		<ContentWrapper height="700px">
			<ImageWrapper>
				<picture>
					<source
						media="(max-width: 930px)"
						srcSet="https://ref.skycop.com/img/mobileImage.jpg"
					/>
					<source
						media="(min-width: 930px)"
						srcSet="https://ref.skycop.com/img/image.jpg"
					/>
					<img src="https://ref.skycop.com/img/image.jpg" />
				</picture>
			</ImageWrapper>
			<FormWrapper>
				<FormContentWrapper>
					<FormTitle>
						<FormattedMessage
							id="referral.default.view.title"
							values={{
								amount: (
									<FormTitleValue>
										<FormattedMessage id="referral.default.view.title.amount" />
									</FormTitleValue>
								),
							}}
						/>
					</FormTitle>
					<FormContent>
						<FormattedMessage
							id="referral.default.view.description1"
							values={{
								part: (
									<BoldText>
										<FormattedMessage id="referral.default.view.description1.part" />
									</BoldText>
								),
							}}
						/>
					</FormContent>
					<FormContent>
						<FormattedMessage id="referral.default.view.description2" />
					</FormContent>
					<FormEmailInputLabel>
						<FormattedMessage id="referral.default.view.description3" />
					</FormEmailInputLabel>
					<br />
					<InputWrapper>
						<Input
							required
							placeholder={intl.formatMessage({
								id: 'referral.email.input.placeholder',
							})}
							value={email}
							onChange={({ target }) => handleEmailChange(target.value)}
							errorText={error}
						/>
					</InputWrapper>
					<br />
					<Checkbox
						id="terms&conditions"
						label={intl.formatMessage({
							id: 'referral.terms.agreement.title',
						})}
						checked={isChecked}
						onChange={handleTermsCheckbox}
						error={!isCheckboxMarked}
						action={
							<TermsAndConditions
								link={intl.formatMessage({
									id: 'referral.term.and.condition.link2',
								})}
							/>
						}
					/>
					<br />
					<ButtonWrapper>
						<Button onClick={() => handleEmailSubmit()}>
							<FormattedMessage id="referral.submit.button.title" />
						</Button>
					</ButtonWrapper>
				</FormContentWrapper>
				<Footer>
					<HelpWrapper onClick={() => handleNextStep(ROUTES.HOW_IT_WORKS)}>
						<HelpIcon />
						<HelpText>
							<FormattedMessage id="referral.how.it.works.link.title" />
						</HelpText>
					</HelpWrapper>
				</Footer>
			</FormWrapper>
		</ContentWrapper>
	);
};
