import React from 'react';

export const CloseIcon = ({ color }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.75"
			y="0.75"
			width="22.5"
			height="22.5"
			rx="11.25"
			stroke="#717171"
			strokeWidth="1.5"
		/>
		<path
			d="M15.2729 15.0003L8.72705 9"
			stroke="#717171"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<path
			d="M15.2729 9L8.72705 15.0003"
			stroke="#717171"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);

export const ErrorCircle = ({ width }) => (
	<svg
		width={width || '17'}
		height={width || '16'}
		viewBox="0 0 17 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.50016 5.33301V7.99967M8.50016 10.6663H8.50683M15.1668 7.99967C15.1668 11.6816 12.1821 14.6663 8.50016 14.6663C4.81826 14.6663 1.8335 11.6816 1.8335 7.99967C1.8335 4.31778 4.81826 1.33301 8.50016 1.33301C12.1821 1.33301 15.1668 4.31778 15.1668 7.99967Z"
			stroke="#FE6050"
			strokeWidth="1.33333"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const ArrowLeft = () => (
	<svg
		width="15"
		height="15"
		viewBox="0 0 15 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_3689_4271)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.792969 7.5L5.14597 3.146L5.85397 3.853L2.70697 7L14 7L14 8L2.70697 8L5.85397 11.146L5.14597 11.854L0.792969 7.5Z"
				fill="#002864"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3689_4271">
				<rect
					width="15"
					height="15"
					fill="white"
					transform="translate(0 15) rotate(-90)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export const ArrowRight = () => (
	<svg
		width="14"
		height="10"
		viewBox="0 0 14 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.707 5L9.35403 9.354L8.64603 8.647L11.793 5.5L0.500031 5.5L0.500031 4.5L11.793 4.5L8.64603 1.354L9.35403 0.645996L13.707 5Z"
			fill="#002864"
		/>
	</svg>
);

export const HelpIcon = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_3111_4779)">
			<path
				d="M6.06016 5.99967C6.2169 5.55412 6.52626 5.17841 6.93347 4.9391C7.34067 4.69978 7.81943 4.6123 8.28495 4.69215C8.75047 4.772 9.17271 5.01402 9.47688 5.37536C9.78106 5.7367 9.94753 6.19402 9.94683 6.66634C9.94683 7.99967 7.94683 8.66634 7.94683 8.66634M8.00016 11.333H8.00683M14.6668 7.99967C14.6668 11.6816 11.6821 14.6663 8.00016 14.6663C4.31826 14.6663 1.3335 11.6816 1.3335 7.99967C1.3335 4.31778 4.31826 1.33301 8.00016 1.33301C11.6821 1.33301 14.6668 4.31778 14.6668 7.99967Z"
				stroke="#149954"
				strokeWidth="1.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3111_4779">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const CheckedCircle = ({ width, color }) => (
	<svg
		width={width || '16'}
		height={width || '17'}
		viewBox="0 0 16 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4.66699 9.16764L6.66699 11.1676L11.3337 6.50098"
			stroke={color || '#149954'}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.00016 15.1673C11.6821 15.1673 14.6668 12.1825 14.6668 8.50065C14.6668 4.81875 11.6821 1.83398 8.00016 1.83398C4.31826 1.83398 1.3335 4.81875 1.3335 8.50065C1.3335 12.1825 4.31826 15.1673 8.00016 15.1673Z"
			stroke={color || '#149954'}
			strokeWidth="1.5"
		/>
	</svg>
);

export const Copy = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.125 20.25H17.625V21.75H5.25V6.375H6.75V4.875H3.75V23.25H19.125V20.25Z"
			fill="#FABE00"
		/>
		<path
			d="M8.25 0.75V18.75H23.25V7.18936L16.8106 0.75H8.25ZM21.75 17.25H9.75V2.25H14.625V9.375H21.75V17.25ZM21.75 7.875H16.125V2.25H16.1893L21.75 7.81064V7.875Z"
			fill="#FABE00"
		/>
	</svg>
);

export const CopyIcon = () => (
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect x="1" y="0.5" width="23" height="23" rx="5.5" fill="#ECF7F1" />
		<rect x="1" y="0.5" width="23" height="23" rx="5.5" stroke="#717171" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.79997 9.41325C6.31827 9.89494 6.04636 10.6993 6.04636 12.0429V14.6936C6.04636 16.0372 6.31827 16.8416 6.79997 17.3233C7.28166 17.8049 8.08604 18.0769 9.42958 18.0769H12.0804C13.4239 18.0769 14.2283 17.8049 14.71 17.3233C15.1917 16.8416 15.4636 16.0372 15.4636 14.6936V12.6009C15.4636 12.312 15.6978 12.0777 15.9868 12.0777C16.2757 12.0777 16.5099 12.312 16.5099 12.6009V14.6936C16.5099 16.1404 16.2238 17.2892 15.4499 18.0631C14.6759 18.8371 13.5271 19.1232 12.0804 19.1232H9.42958C7.98284 19.1232 6.83401 18.8371 6.06008 18.0631C5.28614 17.2892 5 16.1404 5 14.6936V12.0429C5 10.5961 5.28614 9.44729 6.06008 8.67336C6.83401 7.89943 7.98284 7.61328 9.42958 7.61328H11.5223C11.8112 7.61328 12.0455 7.84752 12.0455 8.13646C12.0455 8.4254 11.8112 8.65964 11.5223 8.65964H9.42958C8.08604 8.65964 7.28166 8.93155 6.79997 9.41325Z"
			fill="#149954"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.323 7.65312C11.5185 7.57214 11.7435 7.6169 11.8931 7.76653L16.3576 12.231C16.5072 12.3806 16.552 12.6056 16.471 12.8011C16.39 12.9966 16.1992 13.1241 15.9876 13.1241H13.7554C12.8893 13.1241 12.1455 12.9865 11.6415 12.4826C11.1376 11.9786 11 11.2348 11 10.3687V8.13648C11 7.92487 11.1275 7.7341 11.323 7.65312ZM12.0464 9.39954V10.3687C12.0464 11.1768 12.1878 11.5491 12.3814 11.7427C12.5751 11.9363 12.9473 12.0778 13.7554 12.0778H14.7246L12.0464 9.39954Z"
			fill="#149954"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.7031 4.64818C11.7031 4.35924 11.9374 4.125 12.2263 4.125H15.0166C15.3055 4.125 15.5398 4.35924 15.5398 4.64818C15.5398 4.93712 15.3055 5.17136 15.0166 5.17136H12.2263C11.9374 5.17136 11.7031 4.93712 11.7031 4.64818Z"
			fill="#149954"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.1081 5.17136C10.2391 5.17136 9.53855 5.87187 9.53855 6.74089C9.53855 7.02984 9.30431 7.26407 9.01537 7.26407C8.72642 7.26407 8.49219 7.02984 8.49219 6.74089C8.49219 5.29398 9.66117 4.125 11.1081 4.125H12.9357C13.2247 4.125 13.4589 4.35924 13.4589 4.64818C13.4589 4.93712 13.2247 5.17136 12.9357 5.17136H11.1081Z"
			fill="#149954"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.4755 8.3125C19.7645 8.3125 19.9987 8.54674 19.9987 8.83568V13.1536C19.9987 14.5238 18.8855 15.637 17.5154 15.637C17.2264 15.637 16.9922 15.4028 16.9922 15.1138C16.9922 14.8249 17.2264 14.5906 17.5154 14.5906C18.3077 14.5906 18.9524 13.9459 18.9524 13.1536V8.83568C18.9524 8.54674 19.1866 8.3125 19.4755 8.3125Z"
			fill="#149954"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.0886 4.16484C15.2841 4.08386 15.5091 4.12862 15.6587 4.27825L19.8442 8.46368C19.9938 8.61331 20.0386 8.83834 19.9576 9.03384C19.8766 9.22934 19.6858 9.3568 19.4742 9.3568H17.3815C16.5678 9.3568 15.8588 9.22793 15.3766 8.74579C14.8945 8.26365 14.7656 7.55466 14.7656 6.74091V4.64819C14.7656 4.43659 14.8931 4.24582 15.0886 4.16484ZM15.812 5.91126V6.74091C15.812 7.4967 15.9447 7.83407 16.1165 8.0059C16.2884 8.17773 16.6257 8.31045 17.3815 8.31045H18.2112L15.812 5.91126Z"
			fill="#149954"
		/>
	</svg>
);

export const BackIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="23.25"
			y="23.25"
			width="22.5"
			height="22.5"
			rx="11.25"
			transform="rotate(180 23.25 23.25)"
			stroke="#149954"
			strokeWidth="1.5"
		/>
		<path
			d="M11.5817 8.1752L7.75691 12M7.75691 12L11.5817 15.8248M7.75691 12L16.2422 12"
			stroke="#149954"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="bevel"
		/>
	</svg>
);

export const ClearIcon = ({ stroke }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.6364 19.0001L4.36353 5"
			stroke={stroke || 'white'}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<path
			d="M19.6364 5L4.36353 19.0001"
			stroke={stroke || 'white'}
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);

export const CloseIconWhite = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.75"
			y="0.75"
			width="22.5"
			height="22.5"
			rx="11.25"
			stroke="#E0E0E0"
			strokeWidth="1.5"
		/>
		<path
			d="M15.2729 15.0003L8.72705 9"
			stroke="#E0E0E0"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
		<path
			d="M15.2729 9L8.72705 15.0003"
			stroke="#E0E0E0"
			strokeWidth="1.5"
			strokeMiterlimit="10"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);

export const CheckMark = ({ color }) => (
	<svg
		width="12"
		height="13"
		viewBox="0 0 12 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 3.5L4.5 9L2 6.5"
			stroke={color || '#333333'}
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="bevel"
		/>
	</svg>
);
