import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { Provider } from 'react-redux';
import store from './store';
import { ModalType } from './src/ModalType/constants';

export default {
	ReferralModal: {
		call: (
			locale,
			onGetReferralClick,
			claimRedirectUrl,
			shareCode,
			onCloseAction,
			modalType
		) => {
			return createRoot(document.querySelector('#skycop-referral')).render(
				<Provider store={store}>
					<App
						locale={locale}
						onGetReferralClick={onGetReferralClick}
						claimRedirectUrl={claimRedirectUrl}
						shareCode={shareCode}
						onCloseAction={onCloseAction}
						modalType={modalType || ModalType.Default}
					/>
				</Provider>
			);
		},
	},
};
