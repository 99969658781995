import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import { BackIcon, HelpIcon } from '../../Icons/Icons';
import {
	FormWrapper,
	FormContentWrapper,
	Footer,
	HelpText,
	ContentWrapper,
	ImageWrapper,
} from '../ReferralModal';
import { Stepper } from './Stepper';
import { TermsAndConditionsFooter } from './TermsAndConditions';

const Title = styled.div`
	font-family: Noto Sans;
	font-size: 48px;
	font-weight: 600;
	color: #000000;

	@media (max-width: 930px) {
		font-size: 30px;
		line-height: 32px;
	}
`;

export const HelpWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const BackBtnContainer = styled.div`
	position: absolute;
	top: 30px;
	display: flex;
	cursor: pointer;
	width: fit-content;

	@media (max-width: 930px) {
		position: static;
		padding: 20px 0px;
	}
`;

const BackBtnText = styled.span`
	color: #717171;
	font-family: Noto Sans;
	font-size: 14px;
	font-weight: 500;
	display: flex;
	align-items: center;
	margin-left: 6px;
`;

const steps = (intl, isFriend) => [
	{
		leftContent: 'O1',
		rightContent: isFriend
			? intl.formatMessage({ id: 'referral.how.it.works.1.step.friend' })
			: intl.formatMessage({ id: 'referral.how.it.works.1.step.advocate' }),
	},
	{
		leftContent: 'O2',
		rightContent: isFriend
			? intl.formatMessage({ id: 'referral.how.it.works.2.step.friend' })
			: intl.formatMessage({ id: 'referral.how.it.works.2.step.advocate' }),
	},
	{
		leftContent: 'O3',
		rightContent: isFriend
			? intl.formatMessage({ id: 'referral.how.it.works.3.step.friend' })
			: intl.formatMessage({ id: 'referral.how.it.works.3.step.advocate' }),
	},
	{
		leftContent: 'O4',
		rightContent: isFriend
			? intl.formatMessage({ id: 'referral.how.it.works.4.step.friend' })
			: intl.formatMessage({ id: 'referral.how.it.works.4.step.advocate' }),
	},
];

export const HowItWorks = ({ isFriend, handleNextStep, prevStep }) => {
	const intl = useIntl();

	return (
		<ContentWrapper>
			<ImageWrapper>
				<picture>
					<source
						media="(max-width: 930px)"
						srcSet="https://ref.skycop.com/img/mobileImage.jpg"
					/>
					<source
						media="(min-width: 930px)"
						srcSet="https://ref.skycop.com/img/image.jpg"
					/>
					<img src="https://ref.skycop.com/img/image.jpg" />
				</picture>
			</ImageWrapper>
			<FormWrapper>
				<FormContentWrapper>
					<BackBtnContainer onClick={() => handleNextStep(prevStep)}>
						<BackIcon />
						<BackBtnText>
							<FormattedMessage id="referral.back.button.title" />
						</BackBtnText>
					</BackBtnContainer>
					<Title>
						<FormattedMessage id="referral.how.it.works.link.title" />
					</Title>
					<Stepper steps={steps(intl, isFriend)} />
				</FormContentWrapper>
				<Footer>
					<HelpWrapper>
						<HelpIcon />
						<HelpText>
							<FormattedMessage id="referral.how.it.works.more.details" />
						</HelpText>
						&nbsp;
						<TermsAndConditionsFooter
							link={intl.formatMessage({
								id: 'referral.term.and.condition.link2',
							})}
						/>
					</HelpWrapper>
				</Footer>
			</FormWrapper>
		</ContentWrapper>
	);
};
