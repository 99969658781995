import React, { useState, useEffect } from 'react';

import { Modal } from '../Modal';
import styled from 'styled-components';
import { EmailInputView } from './components/EmailInputView';
import { ROUTES } from './constants';
import { ShareLinkView } from './components/ShareLinkView';
import { useIntl } from 'react-intl';
import { HowItWorks } from './components/HowItWorks';
import { FriendSharedLink } from './components/FriendSharedLink';
import { axiosInstance } from '../Configs/axios';
import { API_Routes } from '../Configs/routes';
import { useSelector } from 'react-redux';

export const ContentWrapper = styled.div`
	display: flex;
	height: ${({ height }) => (height ? `${height}` : '700px')};
	overflow: hidden;
	@media (max-width: 930px) {
		display: block;
		overflow: unset;
	}
`;

export const ImageWrapper = styled.div`
	width: 440px;
	min-height: 700px;
	@media (max-width: 930px) {
		width: 375px;
		min-height: 360px;
	}
`;

export const FormWrapper = styled.div`
	width: 492px;

	display: flex;
	flex-direction: column;

	@media (max-width: 930px) {
		width: 360px;
	}
`;

export const FormContentWrapper = styled.div`
	flex-grow: 1;
	padding: 0px 30px 0px 30px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
	@media (max-width: 930px) {
		padding: 0px 20px 0px 20px;
	}
`;
export const Footer = styled.footer`
	background-image: linear-gradient(
		to right,
		#333 40%,
		rgba(255, 255, 255, 0) 0%
	);
	background-position: top;
	background-size: 10px 1px;
	background-repeat: repeat-x;

	height: 48px;
`;

export const FormTitle = styled.h2`
	font-family: Noto Sans;
	font-size: 48px;
	font-weight: 600;
	line-height: 57.6px;
	text-align: left;
	margin-bottom: 20px;
	color: #000000;

	@media (max-width: 930px) {
		font-size: 30px;
		margin-bottom: 10px;
		line-height: 32px;
	}
`;

export const FormContent = styled.div`
	font-family: Noto Sans;
	font-size: 16px;
	font-weight: 400;
	line-height: 22.4px;
	text-align: left;
	margin-bottom: 20px;
	color: #000000;
`;

export const FormEmailInputLabel = styled.div`
	font-family: Noto Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 20.72px;
	text-align: left;
	color: #000000;
`;

export const InputWrapper = styled.div``;

export const HelpWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	&:hover {
		cursor: pointer;
	}
`;

export const HelpText = styled.div`
	margin-left: 4px;
	color: #717171;
	font-family: Noto Sans;
	font-size: 12px;
	font-weight: 400;
`;

export const FormTitleValue = styled.span`
	color: #149954;
`;

export const LennuabiModal = ({
	onGetReferralClick,
	claimRedirectUrl,
	shareCode,
	onCloseAction,
}) => {
	const intl = useIntl();
	const modalTypeValue = useSelector((state) => state.modalType);

	const [checkbox, setCheckbox] = useState(false);
	const [isCheckboxMarked, setIsCheckboxMarked] = useState(true);
	const [error, setError] = useState(null);
	const [email, setEmail] = useState('');
	const [shareLink, setShareLink] = useState();

	const [showCopiedLinkInfo, setShowCopiedLinkInfo] = useState(false);
	const [activeStep, setActiveStep] = useState(ROUTES.FRIEND_SHARED_LINK);
	const [prevStep, setPrevStep] = useState(null);
	const [show, setShow] = useState(true);

	useEffect(() => {
		if (!!shareCode) {
			setActiveStep(ROUTES.FRIEND_SHARED_LINK);
		} else {
			setActiveStep(ROUTES.REFERRAL_EMAIL_VIEW);
		}
	}, []);

	const handleModalVisibility = (val) => {
		setShow(val);
	};

	const handleLinkCopy = (link) => {
		setShowCopiedLinkInfo(true);
		navigator.clipboard.writeText(link);

		setTimeout(() => {
			setShowCopiedLinkInfo(false);
		}, 1500);
	};

	const isEmailValid = (email) => {
		const emailRegex = /^.+\@\S+\.\S+$/;
		if (!email) return false;
		return email.match(emailRegex);
	};

	const handleCheckboxChange = (val) => {
		if (val && !!email) {
			setError(null);
		}
		if (!checkbox) {
			setIsCheckboxMarked(true);
		}
		setCheckbox(!checkbox);
	};

	const handleEmailChange = (val) => {
		if (!!val) {
			setError('');
		} else {
			setError(intl.formatMessage({ id: 'referral.email.input.error.text' }));
		}
		setEmail(val);
	};

	const handleEmailSubmit = async (intl) => {
		if (!email || !isEmailValid(email)) {
			setError(intl.formatMessage({ id: 'referral.email.input.error.text' }));
			if (!checkbox) {
				setIsCheckboxMarked(false);
			}
			return;
		}

		if (!checkbox) {
			setIsCheckboxMarked(false);
			return;
		}
		await axiosInstance
			.post(API_Routes.REFERRAL_CREATE, {
				email,
				brand: modalTypeValue.modalType,
			})
			.then(({ data }) => {
				setShareLink(data);
				onGetReferralClick && onGetReferralClick(data);
				setActiveStep(ROUTES.SHARE_LINK_VIEW);
				setPrevStep(ROUTES.REFERRAL_EMAIL_VIEW);
			})
			.catch((e) => {
				setError(intl.formatMessage({ id: 'referral.email.input.error.text' }));
				console.error(e);
			});
	};

	const handleNextStep = (nextStep) => {
		setPrevStep(activeStep);
		setActiveStep(nextStep);
	};

	return (
		<Modal
			show={show}
			handleModalVisibility={handleModalVisibility}
			onCloseAction={onCloseAction}
		>
			{activeStep === ROUTES.REFERRAL_EMAIL_VIEW && (
				<EmailInputView
					email={email}
					handleEmailChange={(val) => handleEmailChange(val)}
					isChecked={checkbox}
					handleTermsCheckbox={() => handleCheckboxChange(!checkbox)}
					error={error}
					handleEmailSubmit={() => handleEmailSubmit(intl)}
					isCheckboxMarked={isCheckboxMarked}
					handleNextStep={handleNextStep}
				/>
			)}
			{activeStep === ROUTES.SHARE_LINK_VIEW && (
				<ShareLinkView
					shareLink={shareLink}
					handleLinkCopy={handleLinkCopy}
					showCopiedLinkInfo={showCopiedLinkInfo}
					handleNextStep={handleNextStep}
				/>
			)}
			{activeStep === ROUTES.FRIEND_SHARED_LINK && (
				<FriendSharedLink
					claimRedirectUrl={claimRedirectUrl}
					handleNextStep={handleNextStep}
				/>
			)}

			{activeStep === ROUTES.HOW_IT_WORKS && (
				<HowItWorks
					isFriend={!!shareCode}
					handleNextStep={handleNextStep}
					prevStep={prevStep}
				/>
			)}
		</Modal>
	);
};
